import React, { useState, useEffect } from 'react';
import './App.css'; // Importe o arquivo CSS para estilização adicional
import whatsappIcon from './whatsapp.png'; // Importe o ícone do WhatsApp
import { Helmet } from 'react-helmet';

function App() {
  const [invoiceData, setInvoiceData] = useState(null);
  const token = window.location.pathname.split('/').pop(); // Extrair o token da URL

  useEffect(() => {
    // Função para buscar os dados da fatura com base no token
    const fetchInvoiceData = async () => {
      try {
        const response = await fetch(`https://api-fatura.ltsolucoes.com/buscar-invoice?uuid=${token}`);
        const data = await response.json();
        setInvoiceData(data.invoice);
      } catch (error) {
        console.error('Erro ao buscar os dados da fatura:', error);
      }
    };

    // Chamando a função ao montar o componente
    fetchInvoiceData();
  }, [token]);

  // Função para lidar com o clique no botão de copiar PIX
  const copyPix = () => {
    const pixInput = document.getElementById('pix-input');
    pixInput.select();
    document.execCommand('copy');
    alert('PIX copiado para a área de transferência!');
  };

  return (
    <div>
      <Helmet>
        <title>LT Soluções - Faturas</title>
        <meta name="description" content="Visualize e pague suas faturas de maneira fácil e conveniente com LT Soluções" />
        <meta property="og:title" content="LT Soluções - Faturas" />
        <meta property="og:description" content="Visualize e pague suas faturas de maneira fácil e conveniente com LT Soluções" />
        <meta property="og:image" content='./conta.png' />
      </Helmet>
      <div className="container is-variable white-background" style={{ maxWidth: '500px', margin: '0 auto' }}>
        <div className="columns is-centered"> {/* Centraliza as colunas */}
          <div className="column is-half has-text-centered"> {/* Define o tamanho da coluna */}
            {invoiceData && (
              <div className="has-text-centered neon-wrapper" style={{ margin: '0px auto' }}>
                <img
                  src="https://central.ltsolucoes.com/admin/images/loginlogo.png"
                  alt="Logo"
                />
              </div>
            )}
          </div>
        </div>

        <div className="columns"> {/* Centraliza as colunas */}
          <div className="column"> {/* Define o tamanho da coluna */}
            {invoiceData && (
              <div className="card mb-5" style={{ maxWidth: '400px', margin: '0 auto' }}> {/* Adiciona margem inferior e define maxWidth */}
                <header className="card-header">
                  <p className="card-header-title">Detalhes do Cliente</p>
                </header>
                <div className="card-content">
                  <div className="content">
                    <p><strong>Nome:</strong> {invoiceData.fullname}</p>
                    <p><strong>Endereço:</strong> {invoiceData.address1}</p>
                    <p><strong>Cidade:</strong> {invoiceData.city}</p>
                  </div>
                </div>
              </div>
            )}

            {invoiceData && (
              <div className="card" style={{ maxWidth: '400px', margin: '0 auto' }}> {/* Define maxWidth */}
                <header className="card-header">
                  <p className="card-header-title">Detalhes da Fatura</p>
                </header>
                <div className="card-content">
                  <div className="content">
                    <p><strong>Data de Vencimento:</strong> {invoiceData.duedate}</p>
                    <p><strong>Valor da Fatura:</strong> {invoiceData.newsubtotal}</p>
                    <p><strong>PIX Copia e Cola:</strong>
                      <div>
                        <span>Para utilizar, simplesmente copie o código pelo botão "Copiar PIX" e cole-o no app de seu banco na opção pix "Copia e Cola".</span>
                      </div>
                      <input
                        id="pix-input"
                        className="input"
                        type="text"
                        value={invoiceData.pix}
                        readOnly
                      />
                      <button className="button is-primary custom-primary" onClick={copyPix}>Copiar PIX</button>
                    </p>
                    <div>
                      <span style={{ textShadow: '0px 0px 2px red', color: 'red' }}>Após realizar o pagamento, nos envie o comprovante via Whatsapp.</span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Adicionando o texto "Contato" e o ícone do WhatsApp */}
            <div className="has-text-centered mt-4">
              <a href="https://wa.me/5518988231851" target="_blank" rel="noopener noreferrer">
                <img src={whatsappIcon} alt="WhatsApp" style={{ width: '30px', height: '30px', marginRight: '10px', verticalAlign: 'middle' }} />
                <span style={{ cursor: 'pointer', textShadow: '0px 0px 5px green', color: '#1c8d05' }} onClick={() => window.open('https://wa.me/5518988231851', '_blank')}>Whatsapp</span>
              </a>
            </div>


          </div>
        </div>

        <div className="columns is-centered"> {/* Centraliza as colunas */}
          <div className="column is-half has-text-centered mt-4"> {/* Define o tamanho da coluna e adiciona margem superior */}
            {invoiceData && (
              <div className="has-text-centered" style={{ maxWidth: '300px', margin: '0 auto' }}>
                <img className="image is-4by3" style={{ margin: '0 auto' }} src={`${invoiceData.pix64}`} alt="PIX" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
